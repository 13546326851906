import {Component, Input} from '@angular/core';
import {Link} from 'ngx-linkifyjs';
import {MatLinkPreviewService} from '../../service/mat-link-preview.service';

@Component({
  selector: 'mat-link-preview-container',
  exportAs: 'matLinkPreviewContainer',
  template: `
    <ng-container *ngIf="!multiple && linkPreviewService.links.length > 0; then first else list"></ng-container>

    <ng-template #first>
      <mat-link-preview [link]="linkPreviewService?.links[0]"
                        [showLoadingsProgress]="showLoadingsProgress">
      </mat-link-preview>
    </ng-template>
    <ng-template #list>
      <div *ngFor="let link of linkPreviewService.links; trackBy: trackLinks">
        <mat-link-preview [link]="link"
                          [showLoadingsProgress]="showLoadingsProgress">
        </mat-link-preview>
      </div>
    </ng-template>
  `,
  styles: [`
    :host{display:block}
  `]
})
export class MatLinkPreviewContainerComponent {

  // to forward
  @Input() color = 'primary'; // accent | warn
  @Input() multiple: boolean;
  @Input() showLoadingsProgress = true;

  constructor(public linkPreviewService: MatLinkPreviewService) {
  }

  trackLinks(index: number, link: Link) {
    return link ? link.href : undefined;
  }
}
