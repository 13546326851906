import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {Link} from 'ngx-linkifyjs';
import {LinkPreview} from '../../interfaces/linkpreview.interface';
import {MatLinkPreviewService} from '../../service/mat-link-preview.service';

@Component({
  selector: 'mat-link-preview',
  exportAs: 'matLinkPreview',
  template: `
    <ng-container *ngIf="!this.linkPreview && !this.loaded && this.showLoadingsProgress;then fetch else preview">
    </ng-container>
    <ng-template #preview>
      <mat-card *ngIf="this.linkPreview">
        <mat-card-content class="cut-text">
          <div class="img-container" *ngIf="linkPreview.image">
            <img mat-card-image [src]="linkPreview?.image">
          </div>
          <div>
            <mat-card-title>{{linkPreview?.title}}</mat-card-title>
            <mat-card-subtitle>{{linkPreview?.description}}</mat-card-subtitle>
            <a [href]="linkPreview?.url" mat-button [color]="color">{{linkPreview?.url}}</a>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>

    <ng-template #fetch>
      <mat-spinner *ngIf="!this.hasError"></mat-spinner>
    </ng-template>
  `,
  styles: [`
    :host{display:block}mat-card-content{flex-direction:row;box-sizing:border-box;display:flex}mat-card-content a{padding-left:0;padding-right:0}.img-container{margin-right:1rem;place-content:center;align-items:center;flex-direction:row;box-sizing:border-box;display:flex;flex:1 1 100%;max-width:20%;padding:24px 16px}.center-auto{margin-left:auto!important;margin-right:auto!important}.cut-text{text-overflow:ellipsis;overflow:hidden}
  `]
})
export class MatLinkPreviewComponent implements OnInit, OnDestroy {

  @Input() link: Link;
  @Input() linkPreview: LinkPreview;

  // forwarded from the container
  @Input() color = 'primary'; // accent | warn
  @Input() showLoadingsProgress = true;

  loaded: boolean;
  hasError: boolean;
  private _subscription: Subscription;

  constructor(public linkPreviewService: MatLinkPreviewService) {
  }

  ngOnInit(): void {
    if (this.link && !this.linkPreview) {
      // this.loaded = false;
      this._subscription = this.linkPreviewService
        .fetchLink(this.link.href)
        .subscribe(value => this.linkPreview = value,
          error => this.hasError = true,
          () => this.loaded = true);
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

}
